<div>

  <header-with-menu [braintreeDefaultCountry]="braintreeDefaultCountry" [showLogo]="showHeaderLogo" [icon]="gundryLogo" [showContent]="showHeaderContent"
    [headerText]="headerText" [showOnOrderForm]="showOnOrderForm"></header-with-menu>

  <div class="topCartDelay" *ngIf="noticeOn">
    <div>
      <div col-12 [innerHTML]="noticeText"></div>
    </div>
  </div>

  <div id="orderForm-validation-error-box" class="errors">
    <div class="errors__row" *ngIf="!couponValidation">
      <div col-12 class="errors__col">
        <ul class="errors__list">
          <li *ngIf="!couponValidation" class="errors__item">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text" [innerHTML]="getLabelCheckout('couponinvalid') ? getLabelCheckout('couponinvalid') : 'Sorry, the coupon code you have entered is invalid. Please check the code and try again.'">Sorry, the <strong>coupon code</strong> you have entered is invalid. Please check
              the code and try again.</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="errors__row" *ngIf="
      submittedAccountForm &&
      (
        (accountForm && accountForm.invalid) ||
        (accountForm && accountForm.valid && accountForm.controls['state'].value === '' && statesList && statesList.length > 0) ||
        (isSelectedCB2 && diffAddress && diffAddress.invalid) ||
        (braintreeEnabled && braintreeInstance && ((braintreeDropInEnabled && !braintreeDropinValid) || (!braintreeHostedFieldsValid && !braintreePayPalTokenizedPayment ) )) ||

        (isSelectedCB2 && diffAddress && diffAddress.valid && diffAddress.controls['shipState'].value === '' && differentAddressStatesList && differentAddressStatesList.length > 0) ||
        rejectOrder
      ) ||
      submittedPaymentForm &&
      (
        (paymentForm && paymentForm.invalid) ||
        rejectOrder
      )
    ">
      <div col-12 class="errors__col">
        <p *ngIf="!rejectOrder" class="errors__info" [innerHTML]="getLabelCheckout('pleasecheck') ? getLabelCheckout('pleasecheck') : 'Please check the following errors'">Please сheck the following errors</p>
        <ul class="errors__list">

          <!-- REJECT ORDER -->
          <li class="errors__item" *ngIf="rejectOrder">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{rejectOrderMessage.header}}</strong></span>
          </li>
          <li class="errors__item" *ngIf="rejectOrder">
            <span class="errors__text">{{rejectOrderMessage.body}}</span>
          </li>

          <!-- ACCOUNT FORM -->
          <li class="errors__item"
            *ngIf="accountForm.controls['firstName'].invalid && accountForm.controls['firstName'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('firstName')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['firstName'].invalid && accountForm.controls['firstName'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('firstName')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['lastName'].invalid && accountForm.controls['lastName'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('lastName')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['lastName'].invalid && accountForm.controls['lastName'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('lastName')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['phone'].invalid && accountForm.controls['phone'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('phone')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['phone'].invalid && accountForm.controls['phone'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('phone')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['email'].invalid && accountForm.controls['email'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('email')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item" *ngIf="
            accountForm.controls['email'].invalid &&
            (
              accountForm.controls['email'].errors.email ||
              accountForm.controls['email'].errors.invalidValidEmail
            ) &&
            accountForm.controls['email'].value &&
            accountForm.controls['email'].value.length > 0
          ">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>Email</strong> {{ getLabelCheckout('address is invalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['confirmEmail'].invalid && accountForm.controls['confirmEmail'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('confirmEmail')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item" *ngIf="
            accountForm.controls['confirmEmail'].invalid &&
            (
              accountForm.controls['confirmEmail'].errors.email ||
              accountForm.controls['confirmEmail'].errors.invalidValidEmail
            ) &&
            accountForm.controls['confirmEmail'].value &&
            accountForm.controls['confirmEmail'].value.length > 0
          ">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('confirmEmail')}}</strong> {{ getLabelCheckout('address is invalid')}}.</span>
          </li>
          <li class="errors__item" *ngIf="
            accountForm.controls['confirmEmail'].invalid &&
            (
              accountForm.controls['email'].invalid &&
              accountForm.controls['email'].errors.invalidConfirmEmails ||
              accountForm.controls['confirmEmail'].errors.invalidConfirmEmails
            ) &&
            accountForm.controls['email'].value &&
            accountForm.controls['email'].value.length > 0 &&
            accountForm.controls['confirmEmail'].value &&
            accountForm.controls['confirmEmail'].value.length > 0
          ">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('confirmEmail')}}</strong> does not match Email.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['street'].invalid && accountForm.controls['street'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('address')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['street'].invalid && accountForm.controls['street'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('address')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['city'].invalid && accountForm.controls['city'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('city')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['city'].invalid && accountForm.controls['city'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('city')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['zipPostalCode'].invalid && accountForm.controls['zipPostalCode'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('zip')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['zipPostalCode'].invalid && accountForm.controls['zipPostalCode'].errors.pattern">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('zip')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['country'].invalid && accountForm.controls['country'].errors.required">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('country')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>
          <li class="errors__item"
            *ngIf="accountForm.controls['state'].value === '' && statesList && statesList.length > 0">
            <img src="{{ warningImg }}" alt="" class="errors__image">
            <span class="errors__text"><strong>{{ getLabelCheckout('state')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
          </li>

          <!-- SHIPPING ADDRESS -->
          <div *ngIf="!isPayPalBillingAgreementApproved && buttonList[0].isPlaceOrder && isSelectedCB2 && diffAddress">
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['nameToShip'].invalid && diffAddress.controls['nameToShip'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('shipToName')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['nameToShip'].invalid && diffAddress.controls['nameToShip'].errors.pattern">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('shipToName')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipAddress'].invalid && diffAddress.controls['shipAddress'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('address')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls  && diffAddress.controls['shipAddress'].invalid && diffAddress.controls['shipAddress'].errors.pattern">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('address')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls  && diffAddress.controls['shipCity'].invalid && diffAddress.controls['shipCity'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('city')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCity'].invalid && diffAddress.controls['shipCity'].errors.pattern">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('city')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls  && diffAddress.controls && diffAddress.controls['shipPostal'].invalid && diffAddress.controls['shipPostal'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('zip')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipPostal'].invalid && diffAddress.controls['shipPostal'].errors.pattern">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('zip')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCountry'].invalid && diffAddress.controls['shipCountry'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('country')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
            <li class="errors__item"
              *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipState'].value === '' && differentAddressStatesList && differentAddressStatesList.length > 0">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelShipping('state')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>
          </div>

          <!-- PAYMENT FORM -->
          <div *ngIf="braintreeInstance && ((braintreeDropInEnabled && !braintreeDropinValid) || (!braintreeHostedFieldsValid && !braintreePayPalTokenizedPayment))">
            <li class="errors__item">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text" [innerHTML]="getLabelCheckout('pleaseselect') ? getLabelCheckout('pleaseselect') : 'Please select a payment method'">Please select a payment method.</span>
            </li>
          </div> 


          <div *ngIf="!braintreeInstance && !isPayPalBillingAgreementApproved && buttonList[0].isPlaceOrder">

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['nameOnCard'].errors && paymentForm.controls['nameOnCard'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('nameCard')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['nameOnCard'].errors && paymentForm.controls['nameOnCard'].errors.pattern">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('nameCard')}}</strong> {{ getLabelCheckout('containsinvalid')}}.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['number'].errors && paymentForm.controls['number'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('cardNumber')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['number'].errors && (paymentForm.controls['number'].errors.invalidTypeNumber || paymentForm.controls['number'].errors.invalidNumber)">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text">Invalid <strong>credit card number</strong>.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.controls['expmonth'].errors && paymentForm.controls['expmonth'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('monthDate')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.controls['expyear'].errors && paymentForm.controls['expyear'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('yearDate')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>

            <li class="errors__item" *ngIf="
              submittedPaymentForm && paymentForm.invalid &&
              (
                paymentForm.controls['expmonth'].errors && paymentForm.controls['expmonth'].errors.cardExpired ||
                paymentForm.controls['expyear'].errors && paymentForm.controls['expyear'].errors.cardExpired
              )
            ">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('expirationDate')}}</strong> is invalid. Card has expired.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['code'].errors && paymentForm.controls['code'].errors.required">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('cvv')}}</strong> <span [innerHTML] ="getLabelCheckout('isrequired') ? getLabelCheckout('isrequired') : ' is a requird field'"> is a required field</span>.</span>
            </li>

            <li class="errors__item"
              *ngIf="submittedPaymentForm && paymentForm.invalid && paymentForm.controls['code'].errors && (paymentForm.controls['code'].errors.minlength || paymentForm.controls['code'].errors.maxlength)">
              <img src="{{ warningImg }}" alt="" class="errors__image">
              <span class="errors__text"><strong>{{ getLabelPayment('cvv')}}</strong> is invalid.</span>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <div class="form__grid">

  <div class="form__row tabs tabs__col" *ngIf="braintreeCountrySelector" style="display:contents;cursor:pointer;font-size:small;float:right;"
    class="tooltip-country">



    <div class="tooltip-country-col">🌐
      <span class="tooltiptext-country">
        <p class="tooltipinnerglobe">🌐</p>
        <select style="min-width: 140px;" (change)="changeBraintreeSettings($event)">
          <ng-template ngFor let-item [ngForOf]="braintreeEUCountries" let-i="index" let-isLast="last">
            <option selected *ngIf="item == braintreeDefaultCountry">{{item}}</option>
            <option *ngIf="item != braintreeDefaultCountry">{{item}}</option>
          </ng-template>
        </select>
        <select style="min-width: 140px; margin-right:15px;" (change)="changeBraintreeCurrencySettings($event)">
          <ng-template ngFor let-item [ngForOf]="braintreeCurrencies" let-i="index" let-isLast="last">
            <option selected *ngIf="item == currency">{{item}}</option>
            <option *ngIf="item != currency">{{item}}</option>
          </ng-template>
        </select>
      </span>
    </div>
  </div>

  <!-- TABS -->
  <div class="form__row tabs" *ngIf="tabs">
    <div col-auto class="tabs__col" padding-horizontal>
      <ng-template ngFor let-item [ngForOf]="tabs" let-i="index" let-isLast="last">
        <span class="tabs__label" [ngClass]="{'tabs__label_active': item.active}">{{ item.name }}</span>
        <img class="tabs__image" src="{{ iconArrow }}" alt="" *ngIf="!isLast">
      </ng-template>
    </div>
  </div>

  <!--ORDER DETAIL-->
  <div *ngIf="showProductBox" class="form__row form__row_border order_detail_row">
    <div col-12 class="form__row_padding order_detail_col">
      <div class="order_detail_table">
        <div class="order_detail_table_col">
            <div class="order_detail_title">
              <span class="form__title" [innerHTML]="getLabelCheckout('orderdetail') ? getLabelCheckout('orderdetail') : 'Order Detail'">Order Detail</span>
              <div class="form__title_description mt-5">
                <span [innerHTML]="getLabelCheckout('item') ? getLabelCheckout('item') : 'Item'">Item</span>
                <span [innerHTML]="getLabelCheckout('price') ? getLabelCheckout('price') : 'Price'">Price</span>
              </div>
            </div>
        </div>
        <hr class="order_detail_hr">

        <div class="order_detail_table_col mt-10">
          <div class="order_detail_table_col_content">
            <img class="product_img" *ngIf="currentFunnel?.subscriptionFrequency !== 'Monthly'" src={{newImage}} alt="">
            <img class="product_img" *ngIf="currentFunnel?.subscriptionFrequency === 'Monthly'" src={{currentFunnel?.product?.image[1]?.imageUrl}} alt="">
            <div class="form__title_wrap">
              <div class="form__title_description">
                <span class="order_detail_bold"></span>

                <!-- Default -->
                <span class="order_detail_price" *ngIf="!braintreeShowAUDPriceBox && !braintreeShowCADPriceBox && !braintreeShowUKPriceBox && totalPrice <= 0">{{ ( (1 * currentFunnel?.offerPrice) + ((currentFunnel?.isFreeFirstShipping &&
                  selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost)) + (1 * couponDiscount) ) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency  }}</span>
                <span class="order_detail_price" *ngIf="!braintreeShowAUDPriceBox && !braintreeShowCADPriceBox && !braintreeShowUKPriceBox && coupon.discountType !== 'Free Shipping' && totalPrice > 0">{{ currentFunnel?.offerPrice * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency  }}</span>

                <!-- Australia -->
                <span class="order_detail_price" *ngIf="braintreeShowAUDPriceBox && totalPrice <= 0">{{ ( (1 * currentFunnel?.offerPrice) + ((currentFunnel?.isFreeFirstShipping &&
                  selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost)) + (1 * couponDiscount) ) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency }}</span>
                <span class="order_detail_price" *ngIf="braintreeShowAUDPriceBox && coupon.discountType !== 'Free Shipping' && totalPrice > 0">
                  {{ (currentFunnel?.offerPrice + productTax) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency }}
                </span>

                <!-- Canada -->
                <span class="order_detail_price" *ngIf="braintreeShowCADPriceBox && totalPrice <= 0">{{ ( (1 * currentFunnel?.offerPrice) + ((currentFunnel?.isFreeFirstShipping &&
                  selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost)) + (1 * couponDiscount) ) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency }}</span>
                <span class="order_detail_price" *ngIf="braintreeShowCADPriceBox && coupon.discountType !== 'Free Shipping' && totalPrice > 0">
                  {{ currentFunnel?.offerPrice * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency }}
                </span>

                <!-- United Kingdom -->
                <span class="order_detail_price" *ngIf="braintreeShowUKPriceBox && totalPrice <= 0">
                  {{ ( (1 * currentFunnel?.offerPrice) + ((currentFunnel?.isFreeFirstShipping &&
                  selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost)) + (1 * couponDiscount) ) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) |
                  dynamicCurrency:currency }}
                </span>
                <span class="order_detail_price" *ngIf="braintreeShowUKPriceBox && coupon.discountType !== 'Free Shipping' && totalPrice > 0">
                  {{ ((funnel && funnel.offerPrice  && funnel.offerPrice ? funnel.offerPrice + productTax + shippingTax : 1) * braintreeCurrencyConversion)  |
                  dynamicCurrency:currency }}
                </span>
              </div>
              <div class="form__title_description mt-5">
                <div class="product_description pl-7">
                  <span>Quantity: {{currentFunnel?.product?.description}}</span>
                </div>
                <span class="order_detail_price" *ngIf="currentFunnel?.subscriptionFrequency === 'Monthly'" [innerHTML]="getLabelCheckout('subscription') ? getLabelCheckout('subscription') : 'Subscription'">Subscription</span>
                <span class="order_detail_price" *ngIf="currentFunnel?.subscriptionFrequency !== 'Monthly'" [innerHTML]="getLabelCheckout('onetimepurchase') ? getLabelCheckout('onetimepurchase') : 'One Time Purchase'">One Time Purchase</span>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  </div>

  <!-- BILLING INFORMATION -->
  <div class="form__row form__row_border billing_title_row">
    <div col-12 class="form__row_padding billing_title_col">
      <span class="form__title" [innerHTML]="getLabelCheckout('billinginformation') ? getLabelCheckout('billinginformation') : 'Billing Information'"><span class="form__title_underline">B</span>illing Information</span>
      <a (click)="navigateToPageByGenericUrl('/safe-order', 'name', 'width=400,height=400')">
      <img src="{{ iconPadlock }}" alt="safesecure">
        <p [innerHTML]="getLabelCheckout('safesecure') ? getLabelCheckout('safesecure') : 'Your order is safe and secure'">Your order is safe and secure1111</p>
      </a>
    </div>
  </div>
  <div class="form__row form__row_border form__gradient"></div>
  <div class="form__row form__row_border billing_form_row">
    <div col-12 col-lg-6 push-lg-6 col-md-12 no-padding class="total_col">

    <div [hidden]="isBraintreeLoading" class="price_button"  col-lg-12 col-12>
      <div class="table_coupon_wrapper" col-lg-6>
        <div class="table_wrapper">
          <!-- Default -->
          <table *ngIf="!braintreeShowAUDPriceBox && !braintreeShowCADPriceBox && !braintreeShowUKPriceBox" [ngClass]="braintreeEnabled ? 'tablepadding' : ''"> 
            <tr *ngIf="!hideRegularPrice">
              <td>{{ getLabelCheckout('Regular Price') }}:</td>
              <td class="table_regularPrice">{{ (funnel && funnel.standardPrice ? funnel.standardPrice : (funnel && funnel.offerPrice ? funnel.offerPrice : 1)) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) | dynamicCurrency:currency  }}</td>
            </tr>
            <tr class="table_savings" *ngIf="!hideSavings">
              <td>{{ getLabelCheckout('Savings') }}:</td>
              <td>-{{ ((funnel && funnel.standardPrice && funnel.offerPrice ? (funnel.standardPrice - funnel.offerPrice) : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>
            <tr class="table_regularPrice" *ngIf="showSubtotal">
              <td [innerHTML]="getLabelCheckout('showsub') ? getLabelCheckout('showsub') : 'Subtotal:'">{{ getLabelCheckout('showSubtotal') }}:</td>
              <td>{{ ((funnel && funnel.offerPrice  && funnel.offerPrice ? funnel.offerPrice : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>

            <tr *ngIf="funnelOffersData[0] && (braintreeDefaultCountry != 'United Kingdom' || getBraintreeTaxEstimate() == 0)">
              <td>{{ getLabelCheckout('Sales Tax') }} <span class="tooltip">&nbsp;&#9432; <span class="tooltiptext">Tax estimated for a shipping address in the {{ braintreeDefaultCountry }}.</span></span>:</td>
              <td *ngIf="braintreeClientToken">{{ (totalTax * braintreeCurrencyConversion) | dynamicCurrency:currency }} </td>
              <td *ngIf="!braintreeClientToken">{{ ((!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable &&
                !isPayPalSubscription) ? subscriptionTax : (!funnelOffersData[0].isFreeFirstShipping ? totalTax * braintreeCurrencyConversion :
                (isPayPalSubscription ? totalTax : subscriptionTax))) * braintreeCurrencyConversion | dynamicCurrency:currency  }}</td>
            </tr>

            <tr>
              <td>{{ getLabelCheckout('Shipping') }}:</td>
              <td>{{ ((funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free' ) ? 0 : ((1 * shippingCost) + (1 *
                calculatedShippingCost)) * braintreeCurrencyConversion) | dynamicCurrency:currency  }}</td>
            </tr>
            <tr *ngIf="coupon.discountAmount">
              <td>{{ getLabelCheckout('Coupon discount') }}:</td>
              <td>{{ (couponDiscount * braintreeCurrencyConversion) | dynamicCurrency:currency  }}</td>
            </tr>

            <tr class="total" *ngIf="braintreeDefaultCountry != 'United Kingdom' || getBraintreeTaxEstimate() == 0">
              <td>{{ getLabelCheckout('Total') }} (w/ {{ getLabelCheckout('Sales Tax') }}):</td>
              <td *ngIf="totalPrice <= 0">{{ (1 * (funnel && funnel.offerPrice ? funnel.offerPrice : 1) * braintreeCurrencyConversion) + ((funnel.isFreeFirstShipping &&
                selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost * braintreeCurrencyConversion)) + (1 * couponDiscount * braintreeCurrencyConversion) |
                dynamicCurrency:currency  }} {{ currency !== 'USD' ? currency : '' }}</td>
              <td *ngIf="coupon.discountType !== 'Free Shipping' && totalPrice > 0">{{ (totalPrice * braintreeCurrencyConversion) |
                dynamicCurrency:currency  }} {{ currency !== 'USD' ? currency : '' }}</td>
            </tr>
            <tr class="total" *ngIf="braintreeDefaultCountry == 'United Kingdom' && getBraintreeTaxEstimate() > 0"> 
              <td>{{ getLabelCheckout('Total') }}:</td>
              <td>{{ (totalPrice + getBraintreeTaxEstimate()) * braintreeCurrencyConversion | dynamicCurrency:currency  }} {{ currency !== 'USD' ? currency : '' }}</td>
            </tr>
          </table>

          <!-- Austrlia -->
          <table *ngIf="braintreeShowAUDPriceBox" [ngClass]="braintreeEnabled ? 'tablepadding' : ''"> 
            <tr *ngIf="!hideRegularPrice">
              <td>{{ getLabelCheckout('Regular Price') }}:</td> 
              <td class="table_regularPrice">
                {{ (funnel && funnel.standardPrice ? funnel.standardPrice * taxRatePreEstimate : (funnel && funnel.offerPrice ? funnel.offerPrice + productTax + shippingTax : 1)) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) | dynamicCurrency:currency  }}
              </td>
            </tr>
            <tr class="table_savings" *ngIf="!hideSavings">
              <td>{{ getLabelCheckout('Savings') }}:</td>
              <td>
                -{{
                  (
                    taxRatePreEstimate 
                      ? braintreeSavingsAmt
                      : (
                          funnel && funnel.standardPrice && funnel.offerPrice
                            ? funnel.standardPrice - (totalPrice)
                            : 1
                        )
                  ) * braintreeCurrencyConversion | dynamicCurrency:currency
                }}
              </td>
            </tr>
            <tr class="table_regularPrice" *ngIf="showSubtotal">
              <td [innerHTML]="getLabelCheckout('showsub') ? getLabelCheckout('showsub') : 'Subtotal:'">
              <td>{{ ((funnel && funnel.offerPrice  && funnel.offerPrice ? funnel.offerPrice + productTax + shippingTax : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>
            <tr>
              <td>{{ getLabelCheckout('Shipping') }}:</td>
              <td>
                {{ 
                  (
                    taxRatePreEstimate 
                      ? funnel && funnel.standardPrice && funnel.offerPrice
                        ? (((funnel.standardPrice * this.taxRatePreEstimate) - braintreeSavingsAmt) - (totalPrice + getBraintreeTaxEstimate() * -1)) * -1
                        : 1 * (shippingCost)
                      : (
                          funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free'
                            ? 0 
                            : 1 * (shippingCost)
                        )
                 ) * braintreeCurrencyConversion | dynamicCurrency:currency 
                }}
              </td>
            </tr>
            <tr *ngIf="coupon.discountAmount">
              <td>{{ getLabelCheckout('Coupon discount') }}:</td>
              <td>{{ (couponDiscount * braintreeCurrencyConversion) | dynamicCurrency:currency }}</td>
            </tr>
            <tr class="total"> 
              <td>{{ getLabelCheckout('Total') }} (w/ {{ getLabelCheckout('Sales Tax') }}):</td>
              <td>{{ (totalPrice + getBraintreeTaxEstimate()) * braintreeCurrencyConversion | dynamicCurrency:currency }} {{ currency !== 'USD' ? currency : '' }}</td>
            </tr>
          </table>

          <!-- Cananda -->
          <table *ngIf="braintreeShowCADPriceBox" [ngClass]="braintreeEnabled ? 'tablepadding' : ''"> 
            <tr *ngIf="!hideRegularPrice">
              <td>{{ getLabelCheckout('Regular Price') }}:</td>
              <td class="table_regularPrice">{{ (funnel && funnel.standardPrice ? funnel.standardPrice : (funnel && funnel.offerPrice ? funnel.offerPrice : 1)) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) | dynamicCurrency:currency }}</td>
            </tr>
            <tr class="table_savings" *ngIf="!hideSavings">
              <td>{{ getLabelCheckout('Savings') }}:</td>
              <td>-{{ ((funnel && funnel.standardPrice && funnel.offerPrice ? (funnel.standardPrice - funnel.offerPrice) : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>
            <tr class="table_regularPrice" *ngIf="showSubtotal">
              <td [innerHTML]="getLabelCheckout('showsub') ? getLabelCheckout('showsub') : 'Subtotal:'">{{ getLabelCheckout('showSubtotal') }}:</td>
              <td>{{ ((funnel && funnel.offerPrice  && funnel.offerPrice ? funnel.offerPrice : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>
            <tr *ngIf="funnelOffersData[0] && (getBraintreeTaxEstimate() == 0)">
              <td>{{ getLabelCheckout('Sales Tax') }}:</td>
              <td *ngIf="braintreeClientToken">{{ (totalTax * braintreeCurrencyConversion) | dynamicCurrency:currency}} </td>
              <td *ngIf="!braintreeClientToken">{{ ((!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable &&
                !isPayPalSubscription) ? subscriptionTax : (!funnelOffersData[0].isFreeFirstShipping ? totalTax * braintreeCurrencyConversion :
                (isPayPalSubscription ? totalTax : subscriptionTax))) * braintreeCurrencyConversion | dynamicCurrency:currency }}</td>
            </tr>
            <tr>
              <td>{{ getLabelCheckout('Shipping') }}:</td>
              <td>{{ ((funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free' ) ? 0 : ((1 * shippingCost) + (1 *
                calculatedShippingCost)) * braintreeCurrencyConversion) | dynamicCurrency:currency}}</td>
            </tr>
            <tr *ngIf="coupon.discountAmount">
              <td>{{ getLabelCheckout('Coupon discount') }}:</td>
              <td>{{ (couponDiscount * braintreeCurrencyConversion) | dynamicCurrency:currency}}</td>
            </tr>
            <tr class="total" *ngIf="getBraintreeTaxEstimate() == 0">
              <td>{{ getLabelCheckout('Total') }}:</td>
              <td *ngIf="totalPrice <= 0">{{ (1 * (funnel && funnel.offerPrice ? funnel.offerPrice : 1) * braintreeCurrencyConversion) + ((funnel.isFreeFirstShipping &&
                selectedDeliveryDay == 'Free' ) ? 0 : (1 * shippingCost * braintreeCurrencyConversion)) + (1 * couponDiscount * braintreeCurrencyConversion) |
                dynamicCurrency:currency }} {{ currency !== 'USD' ? currency : '' }}</td>
              <td *ngIf="coupon.discountType !== 'Free Shipping' && totalPrice > 0">{{ (totalPrice * braintreeCurrencyConversion) |
                dynamicCurrency:currency }} {{ currency !== 'USD' ? currency : '' }}</td>
            </tr>
          </table>

          <!-- United Kingdom -->
          <table *ngIf="braintreeShowUKPriceBox" [ngClass]="braintreeEnabled ? 'tablepadding' : ''"> 
            <tr *ngIf="!hideRegularPrice">
              <td>{{ getLabelCheckout('Regular Price') }}:</td> 
              <td class="table_regularPrice">
                {{ (funnel && funnel.standardPrice ? funnel.standardPrice * taxRatePreEstimate : (funnel && funnel.offerPrice ? funnel.offerPrice + productTax + shippingTax : 1)) * (braintreeCurrencyConversion ? braintreeCurrencyConversion : 1) | dynamicCurrency:currency  }}
              </td>
            </tr>
            <tr class="table_savings" *ngIf="!hideSavings">
              <td>{{ getLabelCheckout('Savings') }}:</td>
              <td>
                -{{
                  (
                    taxRatePreEstimate 
                      ? braintreeSavingsAmt
                      : (
                          funnel && funnel.standardPrice && funnel.offerPrice
                            ? funnel.standardPrice - (totalPrice)
                            : 1
                        )
                  ) * braintreeCurrencyConversion | dynamicCurrency:currency
                }}
              </td>
            </tr>
            <tr class="table_regularPrice" *ngIf="showSubtotal">
              <td [innerHTML]="getLabelCheckout('showsub') ? getLabelCheckout('showsub') : 'Subtotal:'">
              <td>{{ ((funnel && funnel.offerPrice  && funnel.offerPrice ? funnel.offerPrice + productTax + shippingTax : 1) * braintreeCurrencyConversion)  | dynamicCurrency:currency }}</td>
            </tr>
            <tr>
              <td>{{ getLabelCheckout('Shipping') }}:</td>
              <td>
                {{ 
                  (
                    taxRatePreEstimate 
                      ? funnel && funnel.standardPrice && funnel.offerPrice
                        ? (((funnel.standardPrice * this.taxRatePreEstimate) - braintreeSavingsAmt) - (totalPrice + getBraintreeTaxEstimate() * -1)) * -1
                        : 1 * (shippingCost)
                      : (
                          funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free'
                            ? 0 
                            : 1 * (shippingCost)
                        )
                 ) * braintreeCurrencyConversion | dynamicCurrency:currency 
                }}
              </td>
            </tr>
            <tr *ngIf="coupon.discountAmount">
              <td>{{ getLabelCheckout('Coupon discount') }}:</td>
              <td>{{ (couponDiscount * braintreeCurrencyConversion) | dynamicCurrency:currency }}</td>
            </tr>
            <tr class="total"> 
              <td>{{ getLabelCheckout('Total') }} (w/ {{ getLabelCheckout('Sales Tax') }}):</td>
              <td>{{ (totalPrice + getBraintreeTaxEstimate()) * braintreeCurrencyConversion | dynamicCurrency:currency }} {{ currency !== 'USD' ? currency : '' }}</td>
            </tr>
          </table>
        </div>

        <!-- Coupon -->
        <div class="coupon_wrapper" *ngIf="!isPayPalBillingAgreementApproved">
          <label for="order-form-coupon-input">{{ getLabelCheckout('couponMessage')}} <i>{{ getLabelCheckout('optional')}}</i>:</label>
          <div class="elements_wrapper">
            <input id="order-form-coupon-input" 
                  [(ngModel)]="couponCode" 
                  type="text" 
                  fixScrollOnMobileiOS
                  (change)="sanitizeCouponCode()"
                  data-qa="coupon-field"
                    />
            <button data-qa="coupon-apply" (click)="getCoupon()">{{getLabelCheckout('Apply')}}</button>
          </div>
        </div>
      </div>

      <div class="buttons_wrapper">
        <button name="PayPal" id="order-form-paypal-button"
          *ngIf="!isPayPalBillingAgreementApproved && (!isSubscription || !isPurchaseAvailable)"
          [hidden]="!showPayPalPayButton" class="paypal_button" alt="PayPal" type="submit"
          (click)="checkoutPayPalWithDifferentAddress(accountForm, diffAddress)"><img *ngIf="showPayPalPayButton" src="{{ iconPaypal }}"
            alt="PayPal" /></button>

        <div [hidden]="isPayPalBillingAgreementApproved || !showAmazonButton" id="AmazonPayButton"
          class="amazonpay_button"></div>

        <div [hidden]="braintreeHidePayPalButton || isBraintreeLoading || !braintreeEnabled" id="paypal-button"></div>
          
      </div>

    </div>

    <div [hidden]="!braintreeClientToken || paypalButtonLoaded()" class="loader"></div>

    </div>
    <div col-12 col-lg-6 pull-lg-6 class="form_col">
      <p class="required_info" [innerHTML]="getLabelCheckout('required')">Required fields are in <strong>bold</strong>.</p>
      
      <!-- BILLING ACCOUNT DETAILS -->
      <account-form 
        [braintreeDefaultZipPostalCode]="braintreeDefaultZipPostalCode" 
        [braintreeDefaultCountry]="braintreeDefaultCountry" 
        [euSpecified]="euSpecified" 
        [guest]="guest" 
        [statesList]="statesList" 
        [countriesList]="countriesList"
        [submittedAccountForm]="submittedAccountForm" 
        [emailToConfirm]="emailToConfirm"
      
        (validateAddressAndCalculateTaxes)="validateAddressAndCalculateTaxes($event)"
        (rememberAccountInformation)="rememberAccountInformation()" 
        (readyToCreateLead)="readyToCreateLead()"
        (emitConfirmEmail)="emailToConfirm=$event" 
        (linkForm)="accountForm=$event">
      </account-form>

      <div class="remember_checkbox">
        <input data-qa="rem-info" class="remember-info-checkbox" type="checkbox" id="remember-pc" (click)="changeStatusIsRemembered()"
          tappable [checked]="isRememberInfoChecked" />
        <label for="remember-pc" id="remember-info" [innerHTML]="getLabelCheckout('remember') ? getLabelCheckout('remember') : 'Remember my Information'">Remember my Information</label>
      </div>
      <div class="comodo_wrapper">

      </div> 
    </div>
  </div>

  <div [hidden]="buttonList[0].isPlaceOrder" class="form__row form__row_border">
    <div class="padding0" col-12 [attr.col-md-4]="isSubscription ? true : null"
      [attr.col-md-6]="isSubscription ? null : true">
      <div id="addressBookWidgetDiv" class="amazon-widget"></div>
    </div>
    <div class="padding0" col-12 [attr.col-md-4]="isSubscription ? true : null"
      [attr.col-md-6]="isSubscription ? null : true">
      <div id="walletWidgetDiv" class="amazon-widget"></div>
    </div>
    <div class="padding0" col-12 [attr.col-md-4]="isSubscription ? true : null" [hidden]="!isSubscription">
      <div id="consentWidgetDiv" class="amazon-widget"></div>
    </div>
  </div>
  <div [hidden]="buttonList[0].isPlaceOrder" class="form__row form__row_border">
    <div col-12 [attr.col-md-4]="isSubscription ? true : null" [attr.col-md-6]="isSubscription ? null : true">
      <div onclick="window.amazon.Login.logout();location.reload(); return false;"
        style="color:lightgray; cursor:pointer; text-decoration: underline; float: left; padding-left: 10px; font-size: x-small;">
        Amazon logout</div>
    </div>
    <div col-12 [attr.col-md-4]="isSubscription ? true : null" [attr.col-md-6]="isSubscription ? null : true">
      <div></div>
    </div>
    <div col-12 [attr.col-md-4]="isSubscription ? true : null" [hidden]="!isSubscription">
      <div></div>
    </div>
  </div>
  <div [hidden]="isPayPalBillingAgreementApproved || !buttonList[0].isPlaceOrder">

    <!-- SHIPPING INFORMATION -->
    <div class="form__row form__row_border">
      <div col-12 class="form__row_padding" [innerHTML]="getLabelCheckout('shippingLabel')"><span class="form__title"><span
            class="form__title_underline">S</span>hipping</span></div>
    </div>
    <div class="form__row form__row_border form__gradient"></div>
    <div class="form__row form__row_border shipping_checkbox_row">
      <div col-12 class="shipping_checkbox_col" [ngClass]="braintreeHideShipping ? 'hidden' : ''">
        <input id="toggle_addres" type="checkbox" (click)="changeStateCB2(); rememberAccountInformation(); handleBraintreeDefaultSetup()" [(ngModel)]="this.isSelectedCB2"/>
        <label for="toggle_addres">{{ getLabelCheckout('diffAddress')}}</label>
      </div>
    </div>
    <div justify-content-start class="form__row form__row_border shipping_form_row" *ngIf="this.isSelectedCB2">
      <div col-12 class="shipping_form_col">
        <shipping-form
          [braintreeDefaultCountry]="braintreeDefaultCountry" 
          [euSpecified]="euSpecified"
          [couponValidation]="couponValidation"
          (shipToNameBinder)="shipToName=$event"
          [submittedAccountForm]="submittedAccountForm" 
          [differentAddressStatesList]="differentAddressStatesList"
          [guest]="guest" 
          [shipToName]="shipToName" 
          [countriesList]="countriesList"
          [shippingAddress]="shippingAddress"
          (validateAddressAndCalculateTaxes)="validateAddressAndCalculateTaxes($event)"
          (rememberAccountInformation)="rememberAccountInformation()" 
          (linkForm)="diffAddress=$event"
          ></shipping-form>
      </div>
    </div>
  </div>

  </div>

  <!-- SHIPPING METHODS -->
  <div *ngIf="!isSelectedCB2"  class="form__row form__row_border shipping_method_row">
    <!-- Default -->
    <div col-12 class="shipping_method_col" *ngIf="guest.country === 'United States'">
      <select
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option value="USPS" selected>Standard USPS (2-7 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>
      </select>
    </div>

    <!-- International Default -->
    <div col-12 class="shipping_method_col" *ngIf="guest.country != 'United States' && guest.country != 'Australia' && guest.country != 'Canada'">
      <select [ngClass]="braintreeEnabled ? 'clamp' : ''"
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>
          International (15-28 Business Days) -  {{ 
            (
              taxRatePreEstimate 
                ? funnel && funnel.standardPrice && funnel.offerPrice
                  ? (((funnel.standardPrice * this.taxRatePreEstimate) - braintreeSavingsAmt) - (totalPrice + getBraintreeTaxEstimate() * -1)) * -1
                  : 1 * (shippingCost)
                : (
                    funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free'
                      ? 0 
                      : 1 * (shippingCost)
                  )
           ) * braintreeCurrencyConversion | dynamicCurrency:currency 
          }}
        </option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{ accountForm ? getLabel(accountForm.controls['country'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>

      </select>
    </div>

    <!-- Australia -->
    <div col-12 class="shipping_method_col" *ngIf="guest.country === 'Australia'">
      <select [ngClass]="braintreeEnabled ? 'clamp' : ''"
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>
          International (15-28 Business Days) -  {{ 
            (
              taxRatePreEstimate 
                ? funnel && funnel.standardPrice && funnel.offerPrice
                  ? (((funnel.standardPrice * this.taxRatePreEstimate) - braintreeSavingsAmt) - (totalPrice + getBraintreeTaxEstimate() * -1)) * -1
                  : 1 * (shippingCost)
                : (
                    funnel.isFreeFirstShipping && selectedDeliveryDay == 'Free'
                      ? 0 
                      : 1 * (shippingCost)
                  )
           ) * braintreeCurrencyConversion | dynamicCurrency:currency 
          }}
        </option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{ accountForm ? getLabel(accountForm.controls['country'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency}}</option>

      </select>
    </div>

    <!-- Canada -->
    <div col-12 class="shipping_method_col" *ngIf="guest.country === 'Canada'">
      <select [ngClass]="braintreeEnabled ? 'clamp' : ''"
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>International (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{ accountForm ? getLabel(accountForm.controls['country'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency}}</option>

      </select>
    </div>
  </div>
  
  <div *ngIf="isSelectedCB2" class="form__row form__row_border shipping_method_row">
    <!-- Default -->
    <div col-12 class="shipping_method_col" *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCountry'] && diffAddress.controls['shipCountry'].value  === 'United States'">
      <select
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option value="USPS" selected>Standard USPS (2-7 Business Days) - {{ ((shippingCost + shippingTax) * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>
      </select>
    </div>

    <!-- International Default -->
    <div col-12 class="shipping_method_col" *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCountry'] && diffAddress.controls['shipCountry'].value !== 'United States' && diffAddress.controls['shipCountry'].value !== 'Australia' && diffAddress.controls['shipCountry'].value !== 'Canada'">
      <select
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>International (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency  }}</option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{diffAddress ? getLabel(diffAddress.controls['shipCountry'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>

      </select>
    </div>

    <!-- Australia -->
    <div col-12 class="shipping_method_col" *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCountry'] && diffAddress.controls['shipCountry'].value === 'Australia'">
      <select
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>International (15-28 Business Days) - {{ ((shippingCost + shippingTax) * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{diffAddress ? getLabel(diffAddress.controls['shipCountry'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency}}</option>

      </select>
    </div>

    <!-- Canada -->
    <div col-12 class="shipping_method_col" *ngIf="diffAddress && diffAddress.controls && diffAddress.controls['shipCountry'] && diffAddress.controls['shipCountry'].value === 'Canada'">
      <select
        fixScrollOnMobileiOS
      >
        <option disabled>-Choose a Shipping Method-</option>
        <option *ngIf="braintreeCountryLabel" value="USPS" selected>International (15-28 Business Days) - {{ ((shippingCost + shippingTax) * braintreeCurrencyConversion) | dynamicCurrency:currency }}</option>
        <option *ngIf="!braintreeCountryLabel" value="USPS" selected>{{diffAddress ? getLabel(diffAddress.controls['shipCountry'].value) : 'International' }} (15-28 Business Days) - {{ (shippingCost * braintreeCurrencyConversion) | dynamicCurrency:currency}}</option>

      </select>
    </div>
  </div>

  <div [hidden]="isBraintreeLoading || isPayPalBillingAgreementApproved || !buttonList[0].isPlaceOrder">

    <!-- PAYMENT INFORMATION -->
    <div class="form__row form__row_border" [ngClass]="braintreePayPalTokenizedPayment ? 'hidden' : ''">
      <div col-12 class="form__row_padding" [innerHTML]="getLabelCheckout('paymentInformation')"><span class="form__title"><span
            class="form__title_underline">P</span>ayment Information</span></div>
    </div>
    <div class="form__row form__row_border payment_row" [ngClass]="braintreePayPalTokenizedPayment ? 'hidden' : ''">
      <div col-12 class="payment_col">
        <div [hidden]="!braintreeClientToken" class="payment_tab_row row" [ngClass]="braintreeHideShipping ? 'hidden' : ''">
          <div class="payment_tab_col col braintree-payment-form" col-auto="">{{getLabelCheckout('cc')}}&nbsp;&nbsp;<img alt="" src="assets/img/payment-card.png"></div>
        </div>

        <div [hidden]="!braintreeClientToken" class="braintree-card-row">
          <div class="payment_form_col col" col-12="">

            <div [hidden]="!braintreeClientToken" id="dropin-container"></div>
            <div [hidden]="!braintreeClientToken" id="dropin-container-paypal" style="display:none;">
              <div (click)="whatispaypal()" class="braintree-dropin braintree-loaded">
                <div style="padding:10px;cursor:pointer;">Paying with PayPal</div>


                <div data-braintree-id="methods-container" class="braintree-methods--active">
                  <div class="braintree-method braintree-method--active" tabindex="0" role="button">
                    <div class="braintree-method__icon-container braintree-method__delete-container">
                      <div class="braintree-method__icon braintree-method__delete">
                        <svg width="48" height="29">
                          <use xlink:href="#iconX"></use>
                        </svg>
                      </div>
                    </div>

                    <div class="braintree-method__logo">
                      <svg width="40" height="24" class="">
                        <use xlink:href="#logoPayPal"></use>
                      </svg>
                    </div>

                    <div class="braintree-method__label">{{ braintreePayPalEmail }}<br>
                      <div class="braintree-method__label--small">PayPal</div>
                    </div>

                    <div class="braintree-method__icon-container braintree-method__check-container">
                      <div class="braintree-method__icon braintree-method__check">
                        <svg height="100%" width="100%">
                          <use xlink:href="#iconCheck"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-braintree-id="toggle" class="braintree-large-button braintree-toggle" tabindex="0"
                  role="button">
                  <span (click)="braintreeChooseAnotherWayToPay($event)">Remember my information and choose another way to
                    pay</span>
                </div>
              </div>
            </div>


            <a [hidden]="braintreeDropinValid || !braintreeDropInEnabled" class="item_link_bt_dr  braintreelink" (click)="navigateToPageByGenericUrl('/what-is-this', 'name', 'width=500,height=330')" href="/what-is-this">What is this?</a>

          </div>
        </div>



        <div [hidden]="!braintreeClientToken || braintreeDropInEnabled" id="dropin-container-hosted-fields"  class="braintree-card-row form__row  payment_row">

          <div class="payment_form_row formBT">
            <div size="12" class="payment_form_col">
              <form class="btpayment_form" id="paymentForm" novalidate>
          
                <div class="form_item_wrapper btrow btfirstrow">
                  <label class="form_item_label" for="nameOnCardBT">{{ getLabelPayment('nameCard') }}</label>
                  <div class="form_item_elements btelements">
                    <div id="nameOnCardBT" class="inputBT"></div>
                    <div id="nameOnCardBTerror" class="hidden">
                      <img alt="field needs attention" src="/assets/img/icon-warning-pointer.gif">
                    </div>
                  </div>
                </div>
          
                <div class="form_item_wrapper btrow">
                  <label class="form_item_label" for="typeOfCardBT">{{ getLabelPayment('cardType') }}</label>
                  <div class="form_item_elements  btelements">
                  
                      <select class="item_select item_cartType" fixscrollonmobileios=""
                        id="typeOfCardBT" name="typeOfCard" required="">
                        <option value="visa">Visa</option>
                        <option value="mastercard">Mastercard</option>
                        <option value="amex">Amex</option>
                      </select>

                  </div>
                </div>
          
                <div class="form_item_wrapper btrow">
                  <label class="form_item_label" for="cardNumberBT">{{ getLabelPayment('cardNumber') }}</label>
                  <div class="form_item_elements btelements">
                    <div id="cardNumberBT" class="inputBT"></div>
                    <div id="cardNumberBTerror" class="hidden">
                      <img alt="field needs attention" src="/assets/img/icon-warning-pointer.gif">
                    </div>
                  </div>
                </div>
          
                <div class="form_item_wrapper btrow">
                  <label class="form_item_label" for="expirationDateBT">{{ getLabelPayment('expirationDate') }}</label>
                  <div class="form_item_elements btelements">
                    <div id="expirationDateBT" class="inputBT"></div>
                    <div id="expirationDateBTerror" class="hidden">
                      <img alt="field needs attention" src="/assets/img/icon-warning-pointer.gif">
                    </div>
                    <span class="item_info_bt">{{ getLabelPayment('expirationDateLabelBT')}}</span>
                  </div>
                </div>
          
                <div class="form_item_wrapper btrow form_item_code btlastrow">
                  <label class="form_item_label" for="cvcCodeBT">{{ getLabelPayment('cvv') }}</label>
                  <div class="form_item_elements btelements">
                    <div id="cvcCodeBT" class="inputBT"></div>
                    <div id="cvcCodeBTerror" class="hidden">
                      <img alt="field needs attention" src="/assets/img/icon-warning-pointer.gif">
                    </div>
                    <a class="item_link_bt" (click)="navigateToPageByGenericUrl('/what-is-this', 'name', 'width=500,height=330')" href="/what-is-this">{{ getLabelPayment('whatsThis') }}</a>
                  </div>
  
                </div>
              </form>
          
            </div>
          </div>
        </div>

        <payment-form [braintreeDefaultCountry]="braintreeDefaultCountry" [hidden]="braintreeClientToken" [cardName]="cardName" [cardType]="cardType"
          (linkForm)="paymentForm=$event" [cardDetails]="cardDetails" [submittedPaymentForm]="submittedPaymentForm"
          [monthArray]="monthArray" [yearArray]="yearArray"></payment-form>
      </div>
    </div>
  </div>


  <!-- COMMENTS -->
  <div class="form__row form__row_border form_row_comments">
  </div>
  <div class="form__row form__row_border form__gradient"></div>
  <div class="form__row form__row_border comment_row">


    <div *ngIf="braintreeDefaultCountry == 'Germany'" class="content_col" >
      <p>Indem Sie auf „Bestellung abschicken“ klicken, erklären Sie sich mit den Allgemeinen 
        <a  (click)="navigateToPageByGenericUrl('https://gundrymd.com/terms/', 'name', 'width=400,height=400')"  target="_blank"><u>Geschäftsbedingungen</u></a> und 
        <a (click)="navigateToPageByGenericUrl('../PrivacyPolicy', 'name', 'width=400,height=400')" target="_blank">
          <u>der Datenschutzrichtlinie</u></a> von Gundry MD einverstanden.</p>
          <p>*Dies ist ein geschätzter Umrechnungskurs, der Ihnen zur Verfügung gestellt wird. Ihr tatsächlicher Umrechnungskurs wird von Ihrer Bank festgelegt.</p>
    </div>

    <div *ngIf="braintreeDefaultCountry == 'Mexico'" class="content_col" >
    <p>Al enviar su pedido a través del Sitio, acepta regirse y cumplir
      <a onclick="window.open('https://gundrymd.com/terminos-y-condiciones/', 'name','width=400,height=400')" style="display: inline" target="_blank"><u>los Términos y condiciones</u></a>
      e
      <a (click)="navigateToPageByGenericUrl('../PrivacyPolicy', 'name', 'width=400,height=400')"><u>la Política de
          privacidad</u></a>
      de Gundry MD.
    </p>
    </div>


    <div *ngIf="braintreeDefaultCountry != 'Germany' && braintreeDefaultCountry != 'Mexico'" col-12 class="content_col" >
      <p>Some countries charge additional tax, custom duties, and fees on goods shipped internationally; check your country custom's office to determine additional costs. <span *ngIf="braintreeDefaultCountry == 'United Kingdom'">Tax estimated for a shipping address in the {{ braintreeDefaultCountry }}.</span></p> 
      <!-- ^ They only want this on UK for now, but we can adjust later to include/exclude other countries. -->
      <p>By clicking Submit Order, you agree to Gundry MD’s
        <a (click)="navigateToPageByGenericUrl('https://gundrymd.com/terms/', 'name', 'width=400,height=400')">Terms & Conditions</a>
        and
        <a (click)="navigateToPageByGenericUrl('https://gundrymd.com/privacy/', 'name', 'width=400,height=400')">Privacy Policy</a>.
      </p>
      <p>{{ commentSectionTxt2 }} </p>
      <p *ngIf="commentSectionTxt3">
        <span [innerHTML]="commentSectionTxt3"></span>
        <a *ngIf="termsURL" (click)="navigateToPageByGenericUrl(termsURL, 'name', 'width=400,height=400')">(All terms
          and conditions apply)</a>
      </p>
      <input type="checkbox" *ngIf="submitOrderCheckBox"
        style="z-index:10000000; min-width: 30px; min-height: 40px; position: absolute;  opacity: 0.0000001;"
        class="submitOrderCheckBoxWrap" (click)="termsAcceptedCheckbox()" tappable />
      <div *ngIf="submitOrderCheckBox" class="submitOrderCheckBoxWrap" (click)="termsAcceptedCheckbox()" tappable>
        <img src="{{ submitOrderCheckboxImg }}" [ngClass]="termsAgreed ? 'hidden' : 'shown'">
        <!-- this is associated with checkout200804a can delete if test doesn't win -->
      </div>
    </div>
    <div col-12 class="button_col">
      <button [hidden]="braintreeClientToken" id="order-form-submit-btn" type="submit"
        [disabled]="(!buttonList[0].isPlaceOrder && isConsentStatus) || (!termsAgreed && submitOrderCheckBox) || isBraintreeLoading" (click)="isPayPalBillingAgreementApproved ?
                  submitPayPalOrder(accountForm) :
                  buttonList[0].isPlaceOrder ?
                    onPayWithDifferentAddressClicked(accountForm, paymentForm, diffAddress) :
                    submitAmazonPay(accountForm)" [innerHTML]="getLabelCheckout('submitorder') ? getLabelCheckout('submitorder') : 'Submit Order'">Submit Order</button>
      <button [hidden]="!braintreeClientToken" id="order-form-submit-btn" type="submit"
      [disabled]="(!buttonList[0].isPlaceOrder && isConsentStatus) || (!termsAgreed && submitOrderCheckBox) || isBraintreeLoading" (click)="payWithBrainTree(accountForm, diffAddress)"  [innerHTML]="getLabelCheckout('submitorder') ? getLabelCheckout('submitorder') : 'Submit Order'">Submit Order</button>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="form__row form__row_border form__gradient_footer"></div>
  <div class="form__row form__row_border footer_row">
    <div col-6 no-padding class="footer_col footer_copyright">
      <p>© {{currentYear}} {{brandFooterObj.label}} - <span [innerHTML]="getLabelCheckout('allrightsreserved') ? getLabelCheckout('allrightsreserved') : 'All Rights Reserved'">All Rights Reserved</span>.</p>
    </div>


  </div>

</div>
